module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-961598-2"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"jamestharpe.com","short_name":"jamestharpe","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff8c5a6462a3fbdc308a7844647e0948"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","customEntries":[]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content"},{"name":"tags"},{"name":"url","store":true}],"resolvers":{"Mdx":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-remark-graph/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null,null],"gatsbyRemarkPlugins":[{"resolve":"/home/james/code/jamestharpe.com/node_modules/@tmus/gatsby-remark-links-normalizer","id":"5a0d968c-bc8c-5131-b1de-1ae8b0e9e8db","name":"@tmus/gatsby-remark-links-normalizer","version":"0.0.2","modulePath":"/home/james/code/jamestharpe.com/node_modules/@tmus/gatsby-remark-links-normalizer/src/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/james/code/jamestharpe.com/node_modules/gatsby-remark-graph","id":"b4278046-cc56-549c-b4c1-96b38378a7cf","name":"gatsby-remark-graph","version":"0.2.6","modulePath":"/home/james/code/jamestharpe.com/node_modules/gatsby-remark-graph/index.js","pluginOptions":{"plugins":[],"language":"mermaid","theme":"forest"},"nodeAPIs":[],"browserAPIs":["onInitialClientRender"],"ssrAPIs":[]},{"resolve":"/home/james/code/jamestharpe.com/node_modules/gatsby-remark-prismjs","id":"8329f325-6749-509b-8a45-2d17f59f05c9","name":"gatsby-remark-prismjs","version":"6.24.0","modulePath":"/home/james/code/jamestharpe.com/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":false,"noInlineHighlight":false,"languageExtensions":[{"language":"superscript","extend":"javascript","definition":{"superscript_types":{}},"insertBefore":{"function":{"superscript_keywords":{}}}}],"prompt":{"user":"root","host":"localhost","global":false},"escapeEntities":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/james/code/jamestharpe.com","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-graph/gatsby-browser.js'),
      options: {"plugins":[],"language":"mermaid","theme":"forest"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
